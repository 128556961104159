@import '../../styles/variables/colors.scss';

//calendar color styling
.react-calendar-datetime-picker .header {
  background-color: $purple !important;
}

.react-calendar-datetime-picker .daysList .is-selected-day {
  background-color: $purple !important;
}

.react-calendar-datetime-picker .daysList .is-today {
  box-shadow: inset 0 0 0 2px $purple !important;
}

.react-calendar-datetime-picker .monthList_month.is-selected-month{
  background-color: $purple !important;
}

.react-calendar-datetime-picker .time .time--input {
  background: white;
}
.react-calendar-datetime-picker .time .time-fieldset .time-fieldset--dec
{
  background: white;
}
.react-calendar-datetime-picker .time .time-fieldset .time-fieldset--inc
{
  background: white;
}