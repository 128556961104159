.userRowSection {
  height: 60px;
  padding: 5px 40px;
  background: white;
  border: 1.5px dashed #845ec3;
  display: flex;
  align-items: center;
  border-radius: 99px;
  justify-content: space-around;
  .ranking {
    display: flex;
    padding: 0 15px;
    border-right: 1px solid white;
    width: 75px;
    color: #845ec3;
  }

  .flag {
    width: 50px;
    height: 40px;
    object-fit: contain;
    // padding: 0 20px 0 5px;
  }
  .img {
    // width: 25;
    height: 40px;
    border-radius: 50%;
    object-fit: contain;
    margin: 0 6px 0 0px;
  }
  .imgDownload{
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .name {
    padding: 15px;
    width: 150px;
    color: #845ec3;
  }

  .userId {
    width: 250px;
    color: #845ec3;
  }

  .amount {
    // margin-right: 50px;
    width: 130px;
    // color: white;
    display: flex;
    align-items: center;
    gap: 15px;
    color: #845ec3;
  }
  .score {
    // margin-left: auto;
    width: 125px;
    display: flex;
    align-items: center;
    color: #845ec3;
    // color: white;
  }
}

@media only screen and (max-width: 1650px) {
  .userRowSection {
    justify-content: space-around;
  }
}

@media only screen and (max-width: 515px) {
  .ranking {
    padding: 0 10px !important;
    border-right: 1px solid white;
  }
  .name {
    padding: 15px !important;
    color: white;
  }
}

@media only screen and (max-width: 445px) {
  .name {
    padding: 5px !important;
    color: white;
  }
}

@media only screen and (max-width: 400px) {
  .userRowSection {
    height: 35px;
    width: 96%;
    background-color: rgb(3, 176, 3);
    display: flex;
    align-items: center;
    border-radius: 99px;
    padding: 5px 0px;
  }
  .score {
    margin-left: auto;
    color: white;
    padding-right: 10px;
  }
}
