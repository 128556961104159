.userListSection {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  background-color: #d6caf0;
}

@media only screen and (max-width: 1650px) {
  .userListSection {
    padding: 20px;
    display: grid;
    grid-gap: 10px;
 
  }
}

@media only screen and (max-width: 445px) {
  .userListSection {
    padding: 20px;
    display: grid;
    grid-template-columns: 45ch;
    grid-gap: 10px;
  }
}

@media only screen and (max-width: 423px) {
  .userListSection {
    padding: 20px;
    display: grid;
    grid-template-columns: 43ch;
    grid-gap: 10px;
  }
}
